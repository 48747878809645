html, body {
    scroll-behavior: smooth;
}

// .main {
//     height: 100vh;
//     height: calc(var(--vh, 1vh) * 100);
//     margin: 0 auto;
// }

.main{
    font-family: 'Helvetica', 'Arial', sans-serif;
    justify-content: center;
    text-align: center;
    >.navigation {
        min-height: 50px;
        display: flex;
        justify-content: space-between;
        text-align: center;
        align-items: center;
        padding: 0 50px;
        a {
            color:black;
            padding: 0 7px;
            &:hover {
                color: $pink;
            }
        }
    }
    >.section {
        min-height: 500px;
        display: flex;
        justify-content: center;
        &.top {
            width: 100%;
            min-height: 600px;
            background-image: url('/images/background-desktop.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            .first-section, .second-section {
                margin: 30px;
                border-radius: 3px;
                background-color: $light-gray;
                > .header {
                    background-color: $dark-blue;
                    padding: 10px;
                    border-top-left-radius: 3px;
                    border-top-right-radius: 3px;
                    text-align: center;
                    > * {
                        color: $white;
                        margin: 0;
                    }
                }
                > .content {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    text-align:left;
                    padding: 20px;
                    > div {
                        width: 100%;
                        input, select {
                            background-color: $gray;
                        }
                    }
                    a { 
                        color: $pink;
                    }
                }
            }
        }
        >.wrapper{
            flex:1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            align-items:center;
            max-width: 1000px;
            width: 100%;
            > * {
                padding: 20px 0px;
            }
        }
        &.top > .wrapper {
            flex-direction: row;
            justify-content: flex-start;
            text-align: left;
            align-items: left;
            > div {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                h1, h3 {
                    color:white !important;
                    font-weight: 700;
                }
            }
            > div:nth-child(1) {
                > * {
                    padding-top: 20px;
                    padding-bottom: 20px;
                    margin: 0;
                }
            }
        }
    }
    >.footer {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        align-items: center;
        width: 100%;
        min-height: 400px;
        background-image: url('/images/footer-background-desktop.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        h1, h3 {
            max-width: 1000px;
            color:white !important;
            padding: 10px;
        }
    }
}

.three-divs {
    display: flex;
    justify-content: center;
    flex-direction: row;
    > div {
        flex: 1;
        padding: 0 20px;
        > * {
            padding: 10px 0px;
        }
    }
}

.img-text-div1, .img-text-div2 {
    display: flex;
    justify-content: center;
    flex-direction: row;
    max-width: 800px;
    >div {
        flex: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: left;
    }
}
.img-text-div1 {
    >div:nth-child(1) {
    }
    >div:nth-child(2) {
        padding-left: 100px;
    }
}

.img-text-div2 {
    >div:nth-child(1) {
        padding-right: 100px;
    }
    >div:nth-child(2) {
        align-items: flex-end;
    }
}

hr { 
    width: 100%;
    max-width: 400px;
    margin:0 auto !important;
}

.privacy-policy, .terms {
    height:100% !important;
    a {
        color: black;
        &:hover {
            color: $pink;
        }
    }
    >.container{
        max-width: 1000px;
        width:100%;
    }
}

.img-success {
    max-width:400px;
    width:100%;
}

.top-header {
    position:absolute;
    z-index: 1;
    right: 0;
    left: 0;
    height: 50px;
    width:100%;
    margin:auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    background-color: $light-gray;
    >div {
        display: flex;
        justify-content: space-between;
        margin:auto;
        align-items: center;
        max-width: 1000px;
        width:100%;
        >a {
            z-index: 4;
            max-height: 50px;
            max-width: 73px;
            >img{
                z-index: 5;
                max-height: 50px;
                max-width: 73px;
            }
        }

    }

}

.logo-container {
    position:absolute;
    z-index: 1;
    right: 0;
    left: 0;
    height: 50px;
    top:20px;
    width:100%;
    margin:auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    >img{
        z-index: 4;
        width:200px;
    }
}

.section{
    text-align: center;
    position: relative;
    height: 100%;
    background-color: white;
}

.success-page {
    display:flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 100vh;
    >img{
        margin: 0 auto;
        max-width: 500px;
        width: 100%;
        height: auto;
    }
}

@media(max-width: 768px) {
    .main{
        >.section.top {
            > .wrapper {
                flex-direction: column;
                > div {
                    padding: 0px !important;
                    > .first-section, > .second-section {
                        margin: 0px;
                    }
                }
            }
        }
    }
}

@media(max-width: 700px) {
    .three-divs {
        flex-direction: column;
    }
    .img-text-div1, .img-text-div2 {
        padding: 10px !important;
        > div {
            padding: 10px !important;
        }
    }
    .img-text-div1 {
        flex-direction: column;
    }
    .img-text-div2 {
        flex-direction: column-reverse;
    }
    .navigation {
        flex-direction: column;
        padding: 0px !important;
    }
}

@media(max-height: 700px) {
}

@media(max-height: 400px) {
}