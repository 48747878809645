.form-button {
    background-color: $pink;
    width: 100%;
    color: $white;
    padding: 10px;
    border: 0px solid;
}

.begin-application-button {
    background-color: white;
    width: 100%;
    max-width: 250px;
    color: black;
    padding: 10px;
    border: 0px solid;
    border-radius: 4px;
}

.three-div-container {
    max-width: 1000px; 
    width:100%;
    margin:0;
    display: flex;
    flex-direction:row;
    align-items: center;
    align-self: center;
}
.first-div, .second-div, .third-div {
    flex:1;
}
.first-div {
    text-align: right;
}

@media(max-width: 700px) {
    .btn.continue-button, .btn.back-button{
        width:100%;
        margin: 0px;
        padding: 0px;
    }
    .btn.select-all-button {
        padding: 0px;
        margin: 0px;
    }
    .three-div-container {
        height: 50px;
    }
    .first-div {
        text-align: center;
        button {
            padding: 0;
            margin: 0;
            height: 50px;
        }
    }
    .second-div {
        flex:3;
    }
}

@media(max-width: 500px) {
    .btn.employment-button, .btn.income-button, .btn.age-button {
        padding:10px;
        margin: 5px;
        flex: 0 0 100%;
        width: 100%;
    }
}