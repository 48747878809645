.main {
    h1{
        font-size: 40px !important;
    }

    h2 {
        font-size: 30px !important;
    }

    h3 {
        font-size: 20px !important;
    }

    h4 {
        font-size: 16px !important;
    }

    h5 {
        font-size: 12px !important;
    }

    h1, h2, h3, h4, h5 {
        color: black;
    }
}

.wrapper {
    h1{
        font-size: 32px !important;
    }

    h2 {
        font-size: 28px !important;
    }

    h3 {
        font-size: 24px !important;
    }

    h4 {
        font-size: 16px !important;
    }

    h5 {
        font-size: 12px !important;
    }

    h1, h2, h3, h4, h5{
        color: black;
    }
}



@media(max-width: 992px) {
    h1{
    font-size: 35px !important;
    }

    h2 {
        font-size: 20px !important;
    }

    h3 {
        font-size: 16px !important;
    }

    h4 {
        font-size: 14px !important;
    }

    h5 {
        font-size: 10px !important;
    }
}

@media(max-width: 500px) {
    h1{
    font-size: 30px !important;
    }

    h2 {
        font-size: 18px !important;
    }

    h3 {
        font-size: 14px !important;
    }

    h4 {
        font-size: 12px !important;
    }

    h5 {
        font-size: 8px !important;
    }
}

@media(max-height: 400px) {
    h1{
    font-size: 24px !important;
    }

    h2 {
        font-size: 15px !important;
    }

    h3 {
        font-size: 11px !important;
    }

    h4 {
        font-size: 9px !important;
    }

    h5 {
        font-size: 6px !important;
    }
}