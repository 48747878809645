.logo {
    width:100%;
    max-width: 150px;
    align-self: center;
}

.all-brands {
    width:100%;
    max-width: 400px;
    align-self: center;
}

.svg {
    max-height: 100px;
}

.small-img {
    width: 75px;
}

.tile-img {
    width:100%;
    max-width: 350px; 
    height: auto;
    object-fit: contain;
}

.partners {
    width: 100%;
    height: 500px;
    background-image: url('/images/sponsors-desktop.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

@media(max-width: 700px) {
    .partners {
        background-image: url('/images/sponsors-mobile.png');
        height: 700px;
    }
}