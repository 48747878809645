// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$dark-blue: #1f3c5e;
$light-gray: #f6f7f9;
$pink: #f44f74;
$gray: #e0e4eb;
$bright-orange: #ff8200;
$orange: #fcb042;
$light-orange: #fff0e0;
$blue: #e8f6fc;
$white: white;

$autoloans-blue: rgb(0, 60, 255);