input {
    // text-align: center;
    &.form-control.rounded-right {
        border-radius: 0px 25px 25px 0px !important;
    }
    &.form-control.rounded-left {
        border-radius: 25px 0px 0px 25px !important;
    }
}

input[type=date]::-webkit-calendar-picker-indicator {
    margin-left: -22px;
}

@media(max-width: 700px) {
    input {
        &.email, &.phone, &.text {
            width: 100%;
            padding:0px 0px;
            font-size: 25px !important;
        }
    }
}

@media(max-height: 400px) {
    input {
        &.email, &.phone, &.text {
            width: 100%;
            padding:0px 0px;
            font-size: 16px !important;
        }
    }
}